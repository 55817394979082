<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        fluid
        :src="pageData.favicon || pageData.logo"
        alt="Fems"
        :style="{ height: '30px' }"
      />
      <h2
        class="brand-text text-primary ml-1"
        :style="{ lineHeight: '30px' }"
      >
        {{ pageData.slug.toUpperCase() }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Old Dashboard
        </h2>
        <p class="mb-2">
          Please Wait
        </p>
        <preloader v-if="isLoading" />

      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

import LocalStorageService from '@/@service/utils/LocalStorage.service'
import AuthService from '@/@service/api/Auth.service'
import Preloader from '../../components/preloader/preloader.vue'

export default {
  components: {
    // VuexyLogo,
    BLink,
    BImg,
    Preloader,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      accessToken: LocalStorageService.get('accessToken'),
      refreshToken: LocalStorageService.get('refreshToken'),
      pageData: {},
      isLoading: true,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    if (this.getPageData) {
      this.pageData = this.getPageData
    } else {
      this.onLogout()
      return
    }
    this.onOldDashboard()
  },
  methods: {
    onOldDashboard() {
      AuthService.userInfo().then(
        res => {
          const response = res.data
          if (response.status) {
            window.location = `${process.env.VUE_APP_CORE_BASE_URL}login/fems_admin_reset_token/?t=${LocalStorageService.get('accessToken')}`
            return
          }
          this.onLogout()
        },

      ).catch(
        () => this.onLogout(),
      )
    },

    // eslint-disable-next-line class-methods-use-this
    onLogout() {
      LocalStorageService.clear()
      window.location.href = `${process.env.VUE_APP_CORE_BASE_URL}login/logout_alt`
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
